/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const availableTaillightColors = /* GraphQL */ `
  query AvailableTaillightColors {
    availableTaillightColors
  }
`;
export const carPrintableLabel = /* GraphQL */ `
  query CarPrintableLabel($instanceId: String) {
    carPrintableLabel(instanceId: $instanceId)
  }
`;
export const carsOnline = /* GraphQL */ `
  query CarsOnline($online: Boolean!) {
    carsOnline(online: $online) {
      ActivationId
      AgentVersion
      ComputerName
      DeviceUiPassword
      IamRole
      InstanceId
      IpAddress
      IsLatestVersion
      LastPingDateTime
      Name
      PingStatus
      PlatformName
      PlatformType
      PlatformVersion
      RegistrationDate
      ResourceType
      Type
      fleetId
      fleetName
    }
  }
`;
export const getAllFleets = /* GraphQL */ `
  query GetAllFleets {
    getAllFleets {
      carIds
      createdAt
      createdBy
      fleetId
      fleetName
    }
  }
`;
export const getAllModels = /* GraphQL */ `
  query GetAllModels($limit: Int, $nextToken: String) {
    getAllModels(limit: $limit, nextToken: $nextToken) {
      models {
        fileMetaData {
          filename
          key
          uploadedDateTime
        }
        modelId
        modelMD5
        modelMetaData {
          actionSpaceType
          metadataMd5
          sensor
          trainingAlgorithm
        }
        modelname
        status
        sub
        username
      }
      nextToken
    }
  }
`;
export const getEvents = /* GraphQL */ `
  query GetEvents {
    getEvents {
      countryCode
      createdAt
      createdBy
      eventDate
      eventId
      eventName
      landingPageConfig {
        links {
          linkDescription
          linkHref
          linkName
        }
      }
      raceConfig {
        averageLapsWindow
        maxRunsPerRacer
        numberOfResetsPerLap
        raceTimeInMin
        rankingMethod
        trackType
      }
      sponsor
      tracks {
        fleetId
        leaderBoardFooter
        leaderBoardTitle
        trackId
      }
      typeOfEvent
    }
  }
`;
export const getLandingPageConfig = /* GraphQL */ `
  query GetLandingPageConfig($eventId: String!) {
    getLandingPageConfig(eventId: $eventId) {
      links {
        linkDescription
        linkHref
        linkName
      }
    }
  }
`;
export const getLeaderboard = /* GraphQL */ `
  query GetLeaderboard($eventId: ID!, $trackId: ID) {
    getLeaderboard(eventId: $eventId, trackId: $trackId) {
      config {
        leaderBoardFooter
        leaderBoardTitle
        sponsor
      }
      entries {
        avgLapTime
        avgLapsPerAttempt
        countryCode
        eventId
        fastestAverageLap {
          avgTime
          endLapId
          startLapId
        }
        fastestLapTime
        lapCompletionRatio
        mostConcecutiveLaps
        numberOfInvalidLaps
        numberOfValidLaps
        racedByProxy
        trackId
        username
      }
    }
  }
`;
export const getRaces = /* GraphQL */ `
  query GetRaces($eventId: String!) {
    getRaces(eventId: $eventId) {
      averageLaps {
        avgTime
        endLapId
        startLapId
      }
      createdAt
      eventId
      laps {
        autTimerConnected
        carName
        isValid
        lapId
        resets
        time
      }
      raceId
      racedByProxy
      trackId
      userId
    }
  }
`;
export const getUploadModelToCarStatus = /* GraphQL */ `
  query GetUploadModelToCarStatus(
    $carInstanceId: String!
    $ssmCommandId: String!
  ) {
    getUploadModelToCarStatus(
      carInstanceId: $carInstanceId
      ssmCommandId: $ssmCommandId
    ) {
      carInstanceId
      ssmCommandId
      ssmCommandStatus
    }
  }
`;
export const listUploadsToCar = /* GraphQL */ `
  query ListUploadsToCar($eventId: ID, $jobId: ID) {
    listUploadsToCar(eventId: $eventId, jobId: $jobId) {
      carFleetId
      carFleetName
      carInstanceId
      carIpAddress
      carName
      endTime
      eventId
      eventName
      jobId
      modelKey
      startTime
      status
      uploadStartTime
      username
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers {
    listUsers {
      Attributes {
        Name
        Value
      }
      Enabled
      MFAOptions {
        Name
        Value
      }
      Roles
      UserCreateDate
      UserLastModifiedDate
      UserStatus
      Username
      sub
    }
  }
`;
export const updateLeaderboardConfigs = /* GraphQL */ `
  query UpdateLeaderboardConfigs(
    $eventId: String!
    $leaderboardConfigs: [LeaderBoardConfigInputType]!
  ) {
    updateLeaderboardConfigs(
      eventId: $eventId
      leaderboardConfigs: $leaderboardConfigs
    ) {
      leaderBoardFooter
      leaderBoardTitle
      sponsor
    }
  }
`;
